<template>
  <div class="w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      v-if="$device.isMobile"
      :title="$t('更改密碼')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :right-text="$t('提交')"
      @on-click-right="onSubmit"
    ></base-header>
    <div class="password-container w-100 p-3 p-md-4">
      <b-form class="password-form" @submit.prevent="onSubmit">
        <b-form-group
          label-for="oldPassword"
          :label="$device.isMobile ? null : '請輸入舊密碼'"
          class="oldPassword-input"
        >
          <b-form-input
            id="oldPassword"
            ref="oldPassword"
            v-model="$v.formData.oldPassword.$model"
            name="oldPassword"
            aria-describedby="input-oldPassword-feedback"
            type="password"
            maxlength="15"
            :placeholder="$t('請輸入舊密碼')"
            :state="formData.isOldPasswordValid"
            @input="oldPasswordInputHandler"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-oldPassword-feedback"
            :state="formData.isOldPasswordValid"
            class="mt-0"
          >
            <div v-if="!$v.formData.oldPassword.required" class="error">
              {{ $t('*請輸入舊密碼') }}
            </div>
            <div
              v-else-if="backendError === 'old password incorrect'"
              class="error"
            >
              {{ $t('*舊密碼輸入錯誤') }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$device.isMobile ? null : '請輸入新密碼'">
          <b-form-input
            id="newPassword"
            ref="newPassword"
            v-model="$v.formData.newPassword.$model"
            name="newPassword"
            aria-describedby="input-newPassword-feedback"
            type="password"
            maxlength="15"
            :placeholder="$t('請輸入8-15位新密碼')"
            :state="formData.isNewPasswordValid"
            @input="newPasswordInputHandler"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-newPassword-feedback"
            :state="formData.isNewPasswordValid"
            class="mt-0"
          >
            <div v-if="!$v.formData.newPassword.required" class="error">
              {{ $t('*請輸入新密碼') }}
            </div>
            <div v-else-if="!$v.formData.newPassword.minLength" class="error">
              {{ $t('*不能少於8碼') }}
            </div>
            <div
              v-else-if="!$v.formData.newPassword.isNotSameAsOld"
              class="error"
            >
              {{ $t('*新密碼和舊密碼一樣') }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$device.isMobile ? null : '再次輸入新密碼'">
          <b-form-input
            id="repeatPassword"
            ref="repeatPassword"
            v-model="$v.formData.repeatPassword.$model"
            name="repeatPassword"
            aria-describedby="input-repeatPassword-feedback"
            type="password"
            maxlength="15"
            :placeholder="$t('請再次輸入新密碼')"
            :state="formData.isRepeatPasswordValid"
            @input="repeatPasswordInputHandler"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-repeatPassword-feedback"
            :state="formData.isRepeatPasswordValid"
            class="mt-0"
          >
            <div v-if="!$v.formData.repeatPassword.required" class="error">
              {{ $t('*請再次輸入新密碼') }}
            </div>
            <div
              v-else-if="!$v.formData.repeatPassword.sameAsNewPassword"
              class="error"
            >
              {{ $t('*兩次新密碼輸入不同') }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
      <div v-if="!$device.isMobile">
        <b-button variant="primary" size="md" @click="onSubmit">
          {{ $t('確定更改') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import {required, sameAs, minLength, not} from 'vuelidate/lib/validators';

export default {
  components: {
    BaseHeader,
  },
  data() {
    return {
      formData: {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
        isOldPasswordValid: null,
        isNewPasswordValid: null,
        isRepeatPasswordValid: null,
      },
      backendError: '',
    };
  },
  validations: {
    formData: {
      oldPassword: {required},
      newPassword: {
        required,
        minLength: minLength(8),
        isNotSameAsOld: not(sameAs('oldPassword')),
      },
      repeatPassword: {
        required,
        sameAsNewPassword: sameAs('newPassword'),
      },
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      this.formData.isOldPasswordValid = null;
      this.formData.isNewPasswordValid = null;
      this.formData.isRepeatPasswordValid = null;
      if (this.$v.$invalid) {
        this.formData.isOldPasswordValid = this.validateOldPassword()
          ? null
          : false;
        this.formData.isNewPasswordValid = this.validateNewPassword()
          ? null
          : false;
        this.formData.isRepeatPasswordValid = this.validateRepeatPassword()
          ? null
          : false;
        this.focusInput();
      } else {
        await this.updatePassword();
      }
    },
    oldPasswordInputHandler() {
      this.backendError = '';
      this.formData.isOldPasswordValid = null;
    },
    newPasswordInputHandler() {
      this.backendError = '';
      this.formData.isNewPasswordValid = null;
    },
    repeatPasswordInputHandler() {
      this.backendError = '';
      this.formData.isRepeatPasswordValid = null;
    },
    validateOldPassword() {
      const {$error} = this.$v.formData.oldPassword;
      return !$error;
    },
    validateNewPassword() {
      const {$error} = this.$v.formData.newPassword;
      return !$error;
    },
    validateRepeatPassword() {
      const {$error} = this.$v.formData.repeatPassword;
      return !$error;
    },
    focusInput() {
      if (!this.validateOldPassword()) {
        this.$refs.oldPassword.focus();
      } else if (!this.validateNewPassword()) {
        this.$refs.newPassword.focus();
      } else if (!this.validateRepeatPassword()) {
        this.$refs.repeatPassword.focus();
      } else {
        return false;
      }
    },
    async updatePassword() {
      this.$store.commit('env/setIsLoading', true);
      try {
        await this.$store.dispatch('user/changePassword', {
          oldPassword: this.formData.oldPassword,
          newPassword: this.formData.newPassword,
        });
        this.$Message.success(this.$t('更改成功'));
        this.$store.commit('env/setIsLoading', false);
        if (!this.$device.isMobile) {
          this.$emit('close');
        } else {
          this.$router.push({name: 'setting'});
        }
      } catch (error) {
        this.backendError = error.response.data.message;
        if (this.backendError === 'old password incorrect') {
          this.formData.isOldPasswordValid = false;
        } else {
          this.$Message.error(this.$t('密碼更改失敗'));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password-container {
  @media screen and (min-width: 768px) {
    background: white;
    box-shadow: 0px 2px 7px 0px #c7c7c74d;
    border-radius: 10px;
  }
  .title {
    @media screen and (min-width: 768px) {
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: $font-grayscale-1;
      padding: 16px 6px 16px 16px;
    }
  }
  .btn-primary {
    width: 240px;
  }
}
.error {
  margin-top: 4px;
  height: 16px;
  color: $negative;
  font-size: 12px;
  text-align: start;
}
.success-wrapper {
  height: calc(100vh - 56px);
  i {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $positive;
    color: #ffffff;
    font-size: 66px;
  }
  span {
    color: $font-grayscale-2;
  }
}
</style>
